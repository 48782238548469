<template>
  <div class="app-container">
    <CrudTable entity="Menu" :columns="columns" :default-filters="{ onlineYN: 1 }" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        {
          field: 'menuCategoryName',
          label: 'common.menuCategory',
          filter: {
            type: 'select',
            options: this.menuCategories,
            valueField: 'name',
            labelField: 'name'
          }
        },
        'position',
        'loyaltyPoints',
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  computed: {
    menuCategories() {
      return this.$store.getters['menuCategory/loadedItems'];
    }
  },
  watch: {
    menuCategories(menuCategories) {
      this.getColumn('menuCategoryName').filter.options = menuCategories;
    }
  },
  created() {
    this.$store.dispatch('menuCategory/getItems');
  },
  methods: {
    getColumn(fieldName) {
      return this.columns.find(c => c.field === fieldName);
    }
  }
};
</script>

<style scoped></style>
